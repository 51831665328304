import { makeStyles } from '@material-ui/styles'
import React from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles((theme) => ({
  interactiveFormCardSelectOptionRoot: ({ isSelected }) => ({
    alignItems: 'center',
    backgroundColor: isSelected
      ? theme.palette.background.lighterSlate
      : theme.palette.background.primary,
    border: `1px solid ${theme.palette.border.gray}`,
    boxShadow: isSelected
      ? `0px 0px 12px 0px ${theme.palette.action.selected}`
      : null,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    gap: '16px',
    height: '100%',
    padding: '16px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.background.lighterSlate,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      padding: '32px',
    },
  }),
  interactiveFormCardSelectOptionImage: {
    maxWidth: '64px',
    width: '100%',
  },
}))

const InteractiveFormCardSelectOption = ({ blok, selectedValue }) => {
  const { image, label, value } = blok
  const isSelected = value === selectedValue
  const classes = useStyles({ isSelected })

  return (
    <SbEditable content={blok}>
      <div className={classes.interactiveFormCardSelectOptionRoot}>
        {!!image.filename && (
          <img
            className={classes.interactiveFormCardSelectOptionImage}
            src={image.filename}
          />
        )}
        <span>{label ? label : value}</span>
      </div>
    </SbEditable>
  )
}

export default InteractiveFormCardSelectOption
